import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/guide/hj006.jpg'


const _hoisted_1 = {
  src: _imports_0,
  class: "guide-image"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("img", _hoisted_1))
}